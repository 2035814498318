import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Cv from './FolioCv'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import Layout from '../../components/Layout'
import { Empty } from './templates'
import Napster from './FolioNapster'
import MelodyVr from './FolioMelodyVr'
import Spotify from './FolioSpotify'
import Volt from './FolioVolt'
import { getFolio } from '../../helpers/dataHelper'
import { logEvent } from '../../helpers/analyticsHelper'
import { useUrlParams } from '../../helpers/layoutHelper'
import './folio.scss'
import Spinner from '../../components/Spinner'

const projectsData = {
  cv: {
    title: 'About me',
    comp: props => <Cv {...props} />,
    backgroundColor: '#000000'
  },
  napster: {
    title: 'Napster',
    comp: props => <Napster {...props} />,
    backgroundColor: '#181D38'
  },
  melodyvr: {
    title: 'MelodyVR',
    comp: props => <MelodyVr {...props} />,
    backgroundColor: '#111111'
  },
  spotify: {
    title: 'Spotify',
    comp: props => <Spotify {...props} />,
    backgroundColor: '#1E1464'
  },
  volt: {
    title: 'Volt',
    comp: props => <Volt {...props} />,
    backgroundColor: '#000000'
  }
}

const EmptyPage = () => {
  return (
    <Layout className='folio'>
      <Empty />
    </Layout>
  )
}

const Folio = () => {
  const [data, setData] = useState(null)
  const loaded = useRef(false)
  const [loading, setLoading] = useState(true)
  const { page } = useParams()
  const project = projectsData[page]
  const isCv = page === 'cv'
  const { id } = useUrlParams(['id'])
  const navigate = useNavigate()
  const { search } = useLocation()

  // Get folio on load
  useEffect(() => {
    if (loaded.current) return
    loaded.current = true
    if (data || !id) return setLoading(false)
    const updateData = async () => {
      if (data) return
      const newData = await getFolio(id)
      newData && setData(newData)
      setLoading(false)
    }
    updateData()
  }, [id, data])

  // Render

  // Update navigation
  const handleProject = useCallback(
    newPage => {
      logEvent('project_pressed', { id: newPage })
      const isCurrent = newPage === page
      if (!isCurrent) navigate(`/folio/${newPage}${search}`)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: isCurrent ? 'smooth' : 'auto'
      })
    },
    [navigate, page, search]
  )

  // Log event
  useEffect(() => {
    if (!id || !data) return
    const { client, recipient } = data
    const payload = { id, client, recipient }
    logEvent('folio_opened', payload)
  }, [id, data])

  // No project for page or no data
  if (!project || (!loading && !data)) return <EmptyPage />
  // Prepare project component
  const { comp, backgroundColor } = project
  const Comp = ({ data, onProject }) => comp({ data, onProject })

  // If we have data and have permission
  const isActive = data && data?.active && data?.projects.includes(page)

  return (
    <Layout
      className={isCv ? 'cv' : 'folio'}
      style={{ backgroundColor: !loading ? backgroundColor : 'transparent' }}
    >
      {isActive && !loading && (
        <>
          {/* Content */}
          {<Comp data={data} onProject={handleProject} />}

          {/* Footer */}
          <Footer
            data={data}
            projectsData={projectsData}
            page={page}
            onProject={handleProject}
          />

          {/* Menu */}
          <Menu
            data={data}
            project={project}
            projectsData={projectsData}
            onProject={handleProject}
          />
        </>
      )}
      {/* Loading */}
      <div className='loading'>
        <Spinner active={loading} />
      </div>
    </Layout>
  )
}

export default Folio
