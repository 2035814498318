import { gql } from '@apollo/client'

const GET_ASSETS = gql`
  query Assets {
    files {
      filename_disk
      filename_download
    }
  }
`

const GET_FOLIO_BY_ID = gql`
  query FolioById($id: String) {
    folio(filter: { id: { _eq: $id } }) {
      client {
        title
        recipient
      }
      title
      message
      active
      projects {
        project_id {
          id
        }
      }
    }
  }
`

export { GET_ASSETS, GET_FOLIO_BY_ID }
