import { ApolloClient, InMemoryCache } from '@apollo/client'
import { GET_ASSETS, GET_FOLIO_BY_ID } from './dataHelperQueries'
const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
const directusUrl: any = process.env.REACT_APP_DIRECTUS_URL
const client: any = new ApolloClient({
  uri: `${directusUrl}/graphql`,
  cache: new InMemoryCache()
})
const clientCdn: any = new ApolloClient({
  uri: `${directusUrl}/graphql/system`,
  cache: new InMemoryCache()
})
const assetsUrl = `${process.env.REACT_APP_DIRECTUS_URL}/assets`
let assets: any = null

const _getAssets = async () => {
  try {
    const req = await clientCdn.query({
      query: GET_ASSETS
    })
    const newAssets: any = req?.data?.files
    if (!newAssets) return
    assets = newAssets.map((a: any) => ({
      id: a.filename_download,
      uri: a.filename_disk
    }))
  } catch (err) {
    isDev && console.log(err)
  }
}

const getAssetUrl = (id: string, params: any) => {
  if (!assets) return null
  const uri = assets.find((a: any) => a.id === id)?.uri
  if (!uri) return null
  const paramsString = params
    ? `?${Object.entries(params)
        .map(([key, value]: any) => {
          return `${key}=${value}`
        })
        .join('&')}`
    : ''
  return `${assetsUrl}/${uri}${paramsString}`
}

const getFolio = async (id: string) => {
  try {
    // Get image and video Ids first
    await _getAssets()
    // Get and return folio data
    const req = await client.query({
      query: GET_FOLIO_BY_ID,
      variables: {
        id
      }
    })
    const folio: any = req?.data?.folio?.[0]
    if (!folio) return null
    const { active, client: folioClient, message, projects, title } = folio
    let res = {
      active,
      client: folioClient?.title,
      recipient: folioClient?.recipient,
      message,
      projects: projects?.map((p: any) => p.project_id?.id),
      title
    }
    return res
  } catch (err) {
    isDev && console.log(err)
    return null
  }
}

export { getFolio, getAssetUrl }
