import Logo from '../../../components/Logo'
import { Card, Col, Row } from '../../../components/Page'

const FolioEmpty = () => {
  return (
    <Card className='empty'>
      <Row>
        <Col className='center'>
          <Logo name='volt' />
        </Col>
      </Row>
    </Card>
  )
}

export default FolioEmpty
